.errorPage {
  margin: 1rem auto;
  padding: 80px 15px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.errorPage h1 {
  font-size: 2.5rem;
  color: var(--main-color);
}

.errorPage p {
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: capitalize;
}

.errorPage img {
  width: min(500px, 90%);
}

.errorPage button {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: white;
  background-color: var(--main-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
}
