@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  line-height: 1;
}

:root {
  --main-color: #294b5a;
  --alt-color: #d3be76;
  --black: #333132;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Noto Sans Arabic", sans-serif;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}
