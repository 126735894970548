nav {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  height: 80px;
}

.nav-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

.logo {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.logo img {
  width: 75px;
}

nav .main-menu {
  display: flex;
  gap: 15px;
  transition: 0.3s;
}

nav .main-menu li {
  position: relative;
  height: 100%;
}

nav .main-menu > li > a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  height: 100%;
  padding: 0 10px;
  white-space: nowrap;
  color: var(--main-color);
  transition: 0.3s;
}

nav li a:hover,
nav li a.active {
  color: var(--alt-color);
}

nav li a.active {
  font-weight: 600;
}

nav li a::after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 0;
  height: 3px;
  background-color: var(--alt-color);
  transition: 0.3s;
}

nav li a:hover::after,
.active::after {
  width: 100%;
}

nav li a i {
  margin-right: 10px;
}

@media (max-width: 768px) {
  nav .container {
    position: relative;
    direction: ltr;
  }

  nav .main-menu {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    top: 80px;
    left: -100%;
    z-index: 9999999999;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(5px);
    transition: 0.3s;
    opacity: 0;
    height: calc(100dvh - 80px);
  }

  nav .main-menu.active {
    left: 0;
    opacity: 1;
  }

  nav .main-menu > li > a {
    height: 40px;
    direction: rtl;
    align-items: center;
  }

  nav .main-menu > li > a:last-of-type {
    margin-bottom: 20px;
  }

  nav li {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

  nav li .sub-menu {
    position: static;
    display: flex;
    transform: none;
  }

  nav li .sub-menu {
    padding: 0;
    max-height: 0;
    transition: 0.3s;
  }

  nav li.shown .sub-menu {
    max-height: 300px;
  }
}

.innerHam,
.innerHam::before,
.innerHam::after {
  background-color: var(--main-color) !important;
}
