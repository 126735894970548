@keyframes zoom-in {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
.landing .slick-slider {
  position: relative;
}

.landing .slick-slide {
  height: 100dvh !important;
}

.landing .slick-slide > div {
  height: 100%;
}

.landing .slick-list {
  margin: 0;
  height: 100dvh;
}
