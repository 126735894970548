footer {
  background-color: var(--main-color);
  color: white;
  margin-top: auto;
}

.footerContainer {
  padding: 50px 15px;
}

footer .top {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

footer .top .title {
  padding: 10px 0;
  width: fit-content;
  border-bottom: 1px solid white;
}

footer .menu,
footer .contact,
footer .location {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

footer .menu a,
footer .contact a {
  color: white;
  font-size: 14px;
  transition: 0.3s;
  cursor: pointer;
}

footer .menu a:hover {
  color: var(--alt-color);
}

footer .contact > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

footer .contact > div > span {
  font-size: 14px;
}

footer .contact > div svg[data-icon] {
  color: var(--alt-color);
  scale: -1 1;
}

footer .location iframe {
  border-radius: 10px;
}

footer .bottom {
  background-color: var(--main-color);
  color: white;
  text-align: center;
  padding: 20px 10px;
}

@media screen and (max-width: 768px) {
  footer .top {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  footer .menu,
  footer .contact,
  footer .location {
    align-items: center;
  }
}
