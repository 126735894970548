h2.main-heading {
  font-size: 1.8rem;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 20px;
  color: var(--main-color);
}

h2.main-heading span.bold {
  font-weight: 700;
}

.cont {
  padding: 50px 15px 25px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

@media (max-width: 768px) {
  .cont {
    flex-direction: column-reverse;
    gap: 30px;
  }
  .cont > main {
    width: 100%;
  }
  .cont > aside {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
  }
}

.review {
  margin-bottom: 10px;
}

svg.star {
  color: var(--main-color);
}

.review span {
  font-size: 14px;
  font-weight: 700;
}

.content p {
  font-size: 18px;
  font-weight: 500;
  color: #222;
  line-height: 1.5;
}

.content :is(h1, h2, h3, h4, h5, h6) {
  color: var(--main-color);
  font-size: 2rem;
  font-weight: 700;
  margin: 20px 0;
}
