.main-title {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 0;
}

.main-title::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: 100%;
  background-image: url(../../assets/images/down_title.png);
  background-repeat: no-repeat;
  background-position: center;
}

.main-title h2 {
  font-size: 30px;
  font-weight: 600;
  color: var(--main-color);
  padding: 0 20px;
}
