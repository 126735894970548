.newsletter {
  background-color: var(--black);
}
.newsletterCont {
  padding: 50px 15px;
  position: relative;
  display: flex;
  justify-content: center;
}

.newsletter form {
  background-color: var(--main-color);
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  border: 2px solid var(--main-color);
  transition: 0.3s;
}

.newsletter form input {
  background-color: var(--main-color);
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  color: white;
  width: 75%;
  font-family: "Noto Sans Arabic", sans-serif;
}

.newsletter form input::placeholder {
  color: rgba(255, 255, 255, 0.3);
  font-family: "Noto Sans Arabic", sans-serif;
}

.newsletter form input:focus {
  outline: none;
}

.newsletter form button {
  background-color: var(--alt-color);
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
  font-family: "Noto Sans Arabic", sans-serif;
  white-space: nowrap;
}

.newsletter form button:hover {
  background-color: var(--main-color);
  color: white;
}
