.slide {
  position: relative;
}

.slide p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.controls {
  position: absolute;
  top: 70%;
  right: 5%;
  transform: translateY(210%);
  display: flex;
  gap: 20px;
}

@media screen and (max-width: 768px) {
  .controls {
    top: 75%;
    right: 50%;
    transform: translateX(50%);
  }
}

.prev,
.next {
  background-color: var(--black);
  color: white;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
