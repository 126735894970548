.slide {
  width: 100%;
  height: 100%;
}

.slide-content {
  position: absolute;
  top: 40%;
  left: 5%;
  width: max(500px, 30%);
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 888888;
}

.slide .slide-bg {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.slide h2 {
  margin: 0;
  color: var(--main-color);
}

.slide p {
  line-height: 1.7;
}

@media (max-width: 768px) {
  .slide-content {
    width: 80%;
    left: 50%;
    right: unset;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .btn {
    margin: auto;
    width: fit-content;
  }
  .controls {
    top: 90%;
    right: 50%;
    transform: translateX(50%);
  }
}
