.landing-sec {
  background-image: var(--landing-bg);
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100dvh;
}

.landing-sec::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 75, 90, 0.7);
}

.headContainer {
  position: relative;
  z-index: 1;
  padding: 50px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  padding: 0 15px;
  color: white;
  gap: 50px;
}

.landing-sec .content h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
}

.landing-sec .content p {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
  text-align: center;
}

.landing-sec .content .btn {
  display: block;
  margin: auto;
  text-align: center;
  width: fit-content;
  border-color: white;
  padding: 1rem 2rem;
  border-radius: 100px;
  color: white;
}

.landing-sec .content .btn:hover {
  border-color: var(--black);
}

.landing-sec .go-down {
  color: white;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s;
}

.landing-sec .go-down svg {
  font-size: 2rem;
  animation: bounce 1.5s cubic-bezier(0.69, -0.01, 0.23, 1) infinite;
}

@media (max-width: 768px) {
  .landing-sec .content h1 {
    font-size: 2rem;
  }
}

@keyframes bounce {
  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40%,
  60% {
    transform: translateY(-10px);
  }
}
